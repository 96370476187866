@tailwind base;
@tailwind components;
@tailwind utilities;

.hover-effect {
  @apply transition duration-500 ease-in-out transform;
}

.hover-effect:hover {
  @apply scale-105;
}
